import React from "react";
import { Link } from "react-router-dom";

const Cta = () => {
  return (
    <>
      <section
        className="download-section pt-100 background-img"
        style={{
          background:
            "url('assets/img/app-hero-bg.jpg') no-repeat center center / cover",
        }}
      >
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-7">
              <div className="download-content text-white pb-100">
                <h2 className="text-white">
                  Siempre un nuevo reto, siempre algo diferente
                </h2>
                <p className="lead">
                  Con Trainify, la diversión y los desafíos nunca terminan. Cada
                  plan de entrenamiento mensual es único y está diseñado a
                  partir de tus evaluaciones físicas, asegurando que siempre
                  estés avanzando hacia tus metas. Al completar un plan, la app
                  te pedirá una nueva evaluación para crear el siguiente reto,
                  manteniendo tus entrenamientos frescos y emocionantes.
                </p>

                <div className="download-btn">
                  <Link to="/" className="btn google-play-btn mr-3">
                    <span className="ti-android"></span> Google Play
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="download-img d-flex align-items-end">
                <img
                  src="assets/img/app-hand-top.png"
                  alt="download"
                  className="img-fluid"
                  style={{ marginBottom: "30px" }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Cta;
