import React from "react";

const Features = () => {
  return (
    <>
      <div className="overflow-hidden">
        <section id="about" className="about-us ptb-100 background-shape-img">
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-7">
                <div className="about-content-left section-heading">
                  <h2>Todo lo que necesitas para entrenar, en una sola app</h2>
                  <div className="single-feature mb-4 mt-5">
                    <h5 style={{ marginLeft: "80px" }}>Guía paso a paso</h5>
                    <div className="icon-box-wrap d-flex align-items-center mb-2">
                      <div className="mr-3 icon-box">
                        <img
                          src="assets/img/book.png"
                          alt="icon"
                          className="img-fluid"
                        />
                      </div>

                      <p className="mb-0">
                        No necesitas ser un experto para usar Trainify. Cada
                        pantalla de la app cuenta con instructivos{" "}
                        <span style={{ color: "black", fontWeight: "bold" }}>
                          dinámicos
                        </span>{" "}
                        que te guían de manera clara y sencilla, asegurando que
                        sepas exactamente qué hacer en cada momento.
                      </p>
                    </div>
                  </div>
                  <div className="single-feature mb-4">
                    <h5 style={{ marginLeft: "80px" }}>Planes científicos</h5>
                    <div className="icon-box-wrap mb-2">
                      <div className="mr-3 icon-box" style={{ padding: 0 }}>
                        <img
                          src="assets/img/smt.png"
                          alt="icon "
                          className="img-fluid"
                          style={{
                            objectFit: "cover",
                            width: "100%",
                            height: "100%",
                          }}
                        />
                      </div>
                      <p className="mb-0">
                        Nuestro sistema ha sido desarrollado por{" "}
                        <span style={{ color: "black", fontWeight: "bold" }}>
                          profesionales
                        </span>
                        en acondicionamiento físico, garantizando que cada
                        ejercicio y rutina te lleve a alcanzar tus{" "}
                        <span style={{ color: "black", fontWeight: "bold" }}>
                          objetivos
                        </span>{" "}
                        de manera segura y efectiva.
                      </p>
                    </div>
                    <p></p>
                  </div>
                  <div className="single-feature mb-4">
                    <h5 style={{ marginLeft: "80px" }}>
                      Entrenamiento flexibles
                    </h5>
                    <div className="icon-box-wrap mb-2">
                      <div className="mr-3 icon-box">
                        <img
                          src="assets/img/calendar.png"
                          alt="icon "
                          className="img-fluid"
                        />
                      </div>
                      <p className="mb-0">
                        Con Trainify, tú tienes el control. Selecciona cuántos
                        días a la semana quieres entrenar y elige los días que
                        mejor se adapten a tu rutina. La app se ajusta a tus
                        compromisos, ayudándote a mantener un{" "}
                        <span style={{ color: "black", fontWeight: "bold" }}>
                          equilibrio
                        </span>{" "}
                        entre tu vida diaria y tus metas de entrenamiento
                      </p>
                    </div>
                    <p></p>
                  </div>
                  <div className="single-feature mb-4">
                    <h5 style={{ marginLeft: "80px" }}>
                      Nivelación por progreso
                    </h5>
                    <div className="icon-box-wrap mb-2">
                      <div className="mr-3 icon-box">
                        <img
                          src="assets/img/stairs.png"
                          alt="icon "
                          className="img-fluid"
                        />
                      </div>
                      <p className="mb-0">
                        Trainify te mantiene enfocado con una fecha límite para
                        cumplir tu plan de entrenamiento. Si logras alcanzar tu
                        objetivo,{" "}
                        <span style={{ color: "black", fontWeight: "bold" }}>
                          subirás de nivel
                        </span>
                        , reflejando tu progreso. Si no cumples, bajarás de
                        nivel, dándote la motivación para seguir mejorando.
                        ¡Desafíate a ti mismo y alcanza nuevas alturas!
                      </p>
                    </div>
                    <p></p>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <div className="about-content-right">
                  <img
                    src="assets/img/image-11.png"
                    alt="about us"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Features;
